<template>
  <div class="withdrawalRecord">
    <div class="merch-search">
      <div class="search-box">
        <!-- 提现类型 -->
        <div class="sch-1 sch-2">
          <div class="sch-title">提现类型:</div>
          <el-select
            v-model="searchData.withdrawalType"
            placeholder="选择提现类型"
          >
            <el-option
              v-for="item in withdrawalTypeList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 提现进度 -->
        <div class="sch-1 sch-2">
          <div class="sch-title">提现进度:</div>
          <el-select
            v-model="searchData.withdrawalProgress"
            placeholder="选择提现进度"
          >
            <el-option
              v-for="item in withdrawalProgressList"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 提现对象,提现单号 -->
        <div class="sch-1 sch-2">
          <div class="sch-title">提现单号:</div>
          <el-input
            placeholder="请输入提现单号"
            v-model="searchData.orderNo"
          ></el-input>
        </div>
        <!-- 退款时间 -->
        <div class="sch-1 sch-2 sch-3">
          <div class="sch-title">退款时间:</div>
          <el-date-picker
            v-model="searchData.createTime"
            type="datetimerange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <!-- 搜索 -->
        <div class="sch-1 sch-2">
          <el-button
            type="primary"
            size="mini"
            @click="getBusinessList(searchData)"
          >
            查询
          </el-button>
        </div>
      </div>
    </div>

    <div class="dataTable">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="提现时间" width="150">
        </el-table-column>
        <el-table-column prop="date" label="提现单号(中金）"> </el-table-column>
        <el-table-column prop="date" label="类型"> </el-table-column>
        <el-table-column prop="date" label="提现对象"> </el-table-column>
        <el-table-column prop="date" label="提现金额"> </el-table-column>
        <el-table-column prop="date" label="提现银行"> </el-table-column>
        <el-table-column prop="date" label="进度"> </el-table-column>
      </el-table>
    </div>

    <div class="footer">
      <el-pagination background layout="prev, pager, next" :total="100">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchData: {
        withdrawalProgress: "",
        withdrawalType: "",
        orderNo: "",
        createTime: "",
      },
      // 时间选择配置项
      pickerOptions: {
        shortcuts: [
          {
            text: "当月月",
            onClick(picker) {
              picker.$emit("pick", [new Date(), new Date()]);
            },
          },
          {
            text: "今年至今",
            onClick(picker) {
              const end = new Date();
              const start = new Date(new Date().getFullYear(), 0);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近六个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              // console.log(end,'end',start,"start");
              start.setMonth(start.getMonth() - 6);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      // 提现类型
      withdrawalTypeList: [
        { name: "个人提现", type: 1 },
        { name: "企业提现", type: 2 },
      ],
      withdrawalProgressList: [
        { name: "处理中", type: 1 },
        { name: "成功", type: 2 },
      ],
      tableData: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.withdrawalRecord {
  // 搜索行样式
  .merch-search {
    margin-top: 1%;
    width: 100%;
    .search-box {
      display: flex;
      flex-wrap: wrap;
    }
    .sch-1 {
      margin-top: 10px;
      width: 15%;
      min-width: 150px;
      display: flex;

      .sch-title {
        width: 80px;
        min-width: 80px;
        height: 100%;
        font-size: 13px;
        background-color: #f5f7fa;
        color: #909399;
        border: 1px solid #dcdfe6;
        border-right: none;
        border-radius: 4px 0px 0px 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 0px 0px 5px !important;
      }
    }
    .sch-2 {
      margin-right: 2%;
    }
    .sch-3{
      width: 30%;
      min-width: 500px;
    }
  }
  .dataTable {
    margin-top: 20px;
  }
  .footer {
    text-align: right;
    margin-top: 20px;
  }
  
}
</style>